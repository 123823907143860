import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

import generic from '@nixweb/nixloc-ui/src/store/modules/generic'
import validation from '@nixweb/nixloc-ui/src/store/modules/validation'
import automation from '@nixweb/nixloc-ui/src/store/modules/automation'
import report from '@nixweb/nixloc-ui/src/store/modules/report'
import user from '@nixweb/nixloc-ui/src/store/modules/user'
import util from '@nixweb/nixloc-ui/src/store/modules/util'

import generateMoviment from './modules/generateMoviment'
import paymentShared from './modules/paymentShared'
import pdv from './modules/pdv'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({ storage: window.sessionStorage, paths: ["user", "generateMoviment"] })],
  modules: {
    generic, validation, automation, user, report, generateMoviment, pdv, paymentShared, util
  }
})