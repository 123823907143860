export default class User {
    constructor() {
        this.photo = "";
        this.type = 2;
        this.name = "";
        this.email = "";
        this.mobile = "";
        this.userName = "";
        this.password = "";
        this.confirmPassword = "";
        this.status = 1;
    }
    update(data) {
        this.id = data.id;
        this.photo = data.photo;
        this.type = data.type;
        this.name = data.name;
        this.email = data.email;
        this.isConfirmedEmail = data.isConfirmedEmail;
        this.mobile = data.mobile;
        this.isConfirmedMobile = data.isConfirmedMobile;
        this.userName = data.userName;
        this.password = data.password;
        this.confirmPassword = data.password;
        this.status = data.status;
    }
}