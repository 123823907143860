<template>
    <div>
        <b-tabs>
            <b-tab v-if="hasRule('Signature')" title="Assinatura Digital">
                <ScrollBar :minHeight="400" :maxHeight="600">
                    <SignerListForMessaging />
                </ScrollBar>
            </b-tab>
            <b-tab title="E-mails Enviados">
                <ScrollBar :minHeight="400" :maxHeight="600">
                    <Emails />
                </ScrollBar>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>

import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import SignerListForMessaging from '../signature/signer/SignerListForMessaging.vue'
import Emails from './Emails.vue'

import { mapGetters } from "vuex";

export default {
    name: "Messaging",
    components: { ScrollBar, Emails, SignerListForMessaging },
    computed: {
        ...mapGetters("user", ["hasRule"]),
    }
}

</script>