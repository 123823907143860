<template>
  <div>
    <div v-if="item.type != 5" @click="timeLineAvailability" class="comum" :class="{
      available: data.available >= 1,
      'available-danger': data.available < 1,
    }">
      {{ data.available }}
      ({{ data.unitOfMeasurementName }})
    </div>
    <div class="material" v-else>
      {{ data.available }}
      ({{ data.unitOfMeasurementName }})
    </div>
    <div class="overlay-time" v-if="show">
      <div>
        <b-row>
          <b-col sm="6">
            <div class="text-left title title-panel">Painel</div>
          </b-col>
          <b-col sm="6">
            <div class="text-right div-icon" @click="show = false">
              <i class="fa-duotone fa-xmark"></i>
            </div>
          </b-col>
        </b-row>
      </div>
      <hr class="hr">
      <TimeLineAvailability />
    </div>
  </div>
</template>
<script>

import TimeLineAvailability from '../../stock/shared/TimeLineAvailability.vue'

import { mapMutations, mapGetters } from "vuex";

export default {

  name: "ItemRentStockAvailability",
  components: { TimeLineAvailability },
  props: {
    item: Object,
    data: Object,
    showButtonBack: Boolean,
  },
  computed: {
    ...mapGetters("generic", ["showModal"]),
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal"]),
    timeLineAvailability() {
      //  this.openModal("timeLineAvailability");
      this.show = true;
      this.addEvent({
        name: "timeLineAvailability",
        data: { item: this.item, showButtonBack: false },
      });
    },
  },
};
</script>
<style scoped>
.comum {
  font-size: 15px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;

}

.comum:hover {
  text-decoration: underline;
}

.available {
  color: darkgreen;
}

.available-danger {
  color: red;
}

.material {
  width: 150px;
  font-size: 13px;
  background-color: #F0F0F0;
  border-radius: 20px;
  margin: auto;
}

.overlay-time {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  background-color: white;
  border: 1px solid #E8EAED !important;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 10px 20px -6px rgb(0 0 0 / 3%);
  z-index: 9999;
}

.div-icon {
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
}

.title-panel {
  font-size: 17px;
}
</style>