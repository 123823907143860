export default {
    namespaced: true,
    state: {
        cashierId: "",
        identification: "",
        collaboratorId: "",
        customerId: "",
        contactId: "",
        periodRent: {},
        itemsRent: [],
        addressRent: [],
        observationRental: "",
        observationServiceOrder: "",
        totalization: {},
        isInvoice: false,
        searchRent: [],
    },
    getters: {
        sumPayment: (state) => {
            let value = 0;
            state.paymentsRent.forEach((payment, index) => {
                value += payment.value;
            });
            return value;
        },
        verifyIfExistProduct: (state) => (item) => {
            var isExist = state.itemsRent.find(obj => {
                return obj.barCode === item.barCode
                    && obj.productId === item.productId
                    && !item.allowWithoutPatrimony
            })
            if (isExist != undefined) return true;
            return false;
        },
    },
    mutations: {
        updateCashier: (state, cashierId) => {
            state.cashierId = cashierId;
        },
        updateRent: (state, rent) => {
            state.identification = rent.identification;
            state.collaboratorId = rent.collaborator.id;
            state.customerId = rent.customer.id;
            state.contactId = rent.contact.id;
            state.observationRental = rent.observationRental;
            state.observationServiceOrder = rent.observationServiceOrder;
        },
        addPeriodRent: (state, obj) => {
            state.periodRent = obj;
        },
        addProduct: (state, obj) => {
            var totalItems = state.itemsRent.length;
            obj.seq = totalItems + 1;
            let discount = { discountByPercentage: false, discount: 0 };
            state.itemsRent.push({ ...obj, discount });
        },
        addAddress: (state, obj) => {
            var totalItems = state.addressRent.length;
            obj.seq = totalItems + 1;
            state.addressRent.push(obj);
        },
        updateProduct: (state, obj) => {
            state.itemsRent.forEach((itemRent, index) => {
                if (itemRent.id === obj.id) {
                    itemRent.description = obj.description;
                    itemRent.unitValue = obj.unitValue;
                    itemRent.discount = obj.discount;
                    itemRent.unitInsurance = obj.unitInsurance;

                    let discount = obj.discount;
                    itemRent.discountByPercentage = discount.discountByPercentage;
                    itemRent.unitDiscount = discount.discount;
                }
            });
        },
        removeProduct: (state, seq) => {
            let filter = state.itemsRent.filter(function (o) {
                return o.seq != seq;
            });
            state.itemsRent = filter;
        },
        removeAddress: (state, seq) => {
            let filter = state.addressRent.filter(function (o) {
                return o.seq != seq;
            });
            state.addressRent = filter;
        },
        updateItemsRent: (state, itemsRent) => {
            state.itemsRent = itemsRent;
        },
        addTotalization: (state, obj) => {
            state.totalization = obj;
        },
        updateIsInvoice: (state, isInvoice) => {
            state.isInvoice = isInvoice;
        },
        updateSearchRent: (state, searchRent) => {
            state.searchRent = searchRent;
        },
        resetRent: (state) => {
            state.cashierPdvId = "";
            state.identification = "";
            state.periodRent = {};
            state.itemsRent = [];
        },
    },
}