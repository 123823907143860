export default {
    namespaced: true,
    state: {
        paymentsRent: [],
    },
    getters: {
        sumPayment: (state) => {
            let value = 0;
            state.paymentsRent.forEach((payment, index) => {
                value += payment.value;
            });
            return value;
        },
    },
    mutations: {
        addPayment: (state, obj) => {
            var totalItems = state.paymentsRent.length;
            obj.seq = totalItems + 1;
            state.paymentsRent.push(obj);
        },
        updatePayment: (state, obj) => {
            state.paymentsRent.forEach((payment, index) => {
                if (payment.seq === obj.seq) {
                    payment.duDate = obj.duDate;
                    payment.value = obj.value;
                }
            });
        },
        removePayment: (state, seq) => {
            let filter = state.paymentsRent.filter(function (o) {
                return o.seq != seq;
            });
            state.paymentsRent = filter;
        },
        resetPayment: (state) => {
            state.paymentsRent = [];
        },
    },
}