<template>
  <div>
    <b-row>
      <b-col sm="12">
        <SelectOption title="Informe o Período" :titleSize="14" :showButtonNext="false" size="small" :width="125"
          :height="45" :options="optionsWithByRules" v-model="generatePeriodRental.typePerid" />
      </b-col>
    </b-row>
    <Alert type="warning" v-if="optionsWithByRules.length == 0">
      <span>
        É necessário configurar o Display (Calculo de Período) em Adm >
        Parâmetro > Locação!
      </span>
    </Alert>
    <div class="div-increment-decrement">
      <b-row v-if="generatePeriodRental.typePerid != 0">
        <b-col sm="12" md="4" lg="4" :xl="isShowStartDateHour ? 3 : 4"></b-col>
        <b-col sm="12" md="4" lg="4" :xl="isShowStartDateHour ? 2 : 3">
          <div class="increment-decrement">
            <IncrementDecrement :min="1" v-model="generatePeriodRental.multiplier" />
          </div>
        </b-col>
        <b-col sm="12" md="4" lg="4" xl="3" v-if="isShowStartDateHour">
          <DateTime title="Iniciar em (opcional)" field="purchaseDate" format="DD/MM/YYYY HH:mm" type="dateTime"
            placeholder :required="false" :markFormDirty="false" v-model="generatePeriodRental.startDateHour" />
        </b-col>
        <b-col sm="12" md="4" lg="4" xl="3">
          <div class="div-btn-generate">
            <Button _key="btnGeneratePeriodRent" title="Gerar" classIcon="fa-solid fa-arrows-rotate"
              :disabled="btnGenerateDisabled" type="info" size="medium" :clicked="generate" />
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="div-period-rent" v-show="showPeriodRent">
      <PeriodRentItem />
    </div>
  </div>
</template>

<script>
import SelectOption from "@nixweb/nixloc-ui/src/component/shared/SelectOption.vue";
import IncrementDecrement from "@nixweb/nixloc-ui/src/component/forms/IncrementDecrement.vue";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import GeneratePeriodRental from "./GeneratePeriodRent.js";
import PeriodRentItem from "./PeriodRentItem.vue";

import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "GeneratePeriodRent",
  props: ["formName", "value", "startDateHour", "isShowStartDateHour"],
  components: {
    SelectOption,
    IncrementDecrement,
    DateTime,
    Button,
    Alert,
    PeriodRentItem,
  },
  data() {
    return {
      generatePeriodRental: new GeneratePeriodRental(),
      showPeriodRent: false,
      btnGenerateDisabled: true,
      urlGenerate: "/api/v1/rental/period-rent/generate",
      options: [
        {
          title: "Minuto",
          titleSize: "14",
          icon: "fa-solid fa-clock-eight",
          value: 1,
        },
        {
          title: "Hora",
          titleSize: "14",
          icon: "fa-solid fa-hourglass",
          value: 2,
        },
        {
          title: "Dia",
          titleSize: "14",
          icon: "fa-solid fa-calendar-day",
          value: 3,
        },
        {
          title: "Semana",
          titleSize: "14",
          icon: "fa-solid fa-calendar-week",
          value: 4,
        },
        {
          title: "Quinzena",
          titleSize: "14",
          icon: "fa-solid fa-calendar-lines",
          value: 5,
        },
        {
          title: "Mês",
          titleSize: "14",
          icon: "fa-solid fa-calendar-range",
          value: 6,
        },
        {
          title: "Ano",
          titleSize: "14",
          icon: "fa-solid fa-calendar-days",
          value: 7,
        },
      ],
    };
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("generic", ["event"]),
    optionsWithByRules() {
      let displayPeriod = this.userLogged.parameterRental.displayPeriod;
      let options = [];
      this.options.forEach((item) => {
        if (!displayPeriod.includes("1") && item.value == 1) return;
        if (!displayPeriod.includes("2") && item.value == 2) return;
        if (!displayPeriod.includes("3") && item.value == 3) return;
        if (!displayPeriod.includes("4") && item.value == 4) return;
        if (!displayPeriod.includes("5") && item.value == 5) return;
        if (!displayPeriod.includes("6") && item.value == 6) return;
        if (!displayPeriod.includes("7") && item.value == 7) return;
        options.push(item);
      });

      return options;
    },
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading"]),
    generate() {
      if (this.startDateHour)
        this.generatePeriodRental.startDateHour = this.startDateHour;

      let params = {
        url: this.urlGenerate,
        obj: this.generatePeriodRental,
        notNotifyToast: true,
        notRemoveFormDirty: true,
      };
      this.postApi(params).then((response) => {
        this.addEvent({ name: "generatePeriodRent", data: response.content });
        this.$emit("input", response.content);
        this.showPeriodRent = true;
        this.btnGenerateDisabled = true;
        this.removeLoading(["btnGeneratePeriodRent"]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "periodRentCreateUpdate") {
          this.generatePeriodRental.multiplier = 1;
          this.showPeriodRent = false;
          this.btnGenerateDisabled = false;
        }

        if (event.name == "cleanGeneratePeriodRent") {
          this.generatePeriodRental = new GeneratePeriodRental();
          this.showPeriodRent = false;
        }
      },
      deep: true,
    },
    generatePeriodRental: {
      handler() {
        this.btnGenerateDisabled = false;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-increment-decrement {
  margin: auto;
}

.increment-decrement {
  margin-top: 36px;
  max-width: 120px;
}

.div-btn-generate {
  margin-top: 33px;
}

.div-period-rent {
  margin-top: 15px;
  margin-bottom: 10px;
}
</style>
