<template>
  <div>
    <div class="text-center">
      <div class="period-rent-item" @click="update">
        <DisplayPeriodRent :periodRent="periodRent" />
      </div>
    </div>
  </div>
</template>
<script>
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import DisplayPeriodRent from "@nixweb/nixloc-ui/src/component/rental/DisplayPeriodRent";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PeriodRentItem",
  components: {
    Modal,
    DisplayPeriodRent,
  },
  data() {
    return {
      periodRent: {},
    };
  },
  computed: {
    ...mapGetters("generic", ["event", "showModal"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal"]),
    update() {
      this.addEvent({
        name: "clickedPeriodRentItem",
        data: this.periodRent,
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "generatePeriodRent") this.periodRent = event.data;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.period-rent-item {
  cursor: pointer;
  max-width: 550px;
  border: 1px solid #e5e4e8;
  background-color: white;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 7px;
  font-size: 14px;
  font-weight: normal;
  margin: auto;
  box-shadow: 0px 10px 20px -6px rgb(0 0 0 / 2%);
}
</style>
