<template>
  <div>
    <transition name="fade">
      <div class="full-page" v-if="showDuplicity">
        <div class="n-div-center">
          <div class="text-center logo">
            <img src="https://nixloc.com.br/wp-content/themes/nix-loc/images/logo.svg" alt="Nixloc" />
          </div>
          <br />
          <div>
            <div class="row">
              <div class="col-12 text-center">
                <div class="icon-siren">
                  <i class="fa-solid fa-triangle-exclamation"></i>
                </div>
              </div>
            </div>
            <div class="text-center">
              <Timer :title="'Detectamos que o usuário (' + userName + ') teve acesso simultâneo e será desconectado em'"
                :initialTimer="5" :endTime="logout" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>

import * as signalR from "@microsoft/signalr";
import Timer from "@nixweb/nixloc-ui/src/component/shared/Timer";

import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "SignalrUser",
  components: {
    Timer
  },
  data() {
    return {
      signalConnection: null,
      userName: "",
      codeBrowser: "",
      companyId: 0,
      showDuplicity: false,
    };
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("generic", ["event"]),
  },
  mounted() {
    this.codeBrowser = localStorage.codeBrowser;
    this.connectionBuilder();
  },
  beforeDestroy() {
    this.logout();
  },
  methods: {
    ...mapMutations("user", ["addUserLogged"]),
    ...mapMutations("generic", ["addEvent", "removeLoading"]),
    connectionBuilder() {
      var url = process.env.VUE_APP_API_URL;

      this.userId = this.userLogged.user.id;
      this.userName = this.userLogged.user.userName;
      this.companyId = this.userLogged.companyId;

      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(`${url}signalr`)
        .build();

      this.connection.start().then(() => {
        this.addUserLogged();

        this.connection.on("NotifyDuplicityUser", (userName, codeBrowser) => {
          if (this.userName == userName && this.codeBrowser == codeBrowser) {
            this.showDuplicity = true;
          }
        });

        this.connection.on("NotifyDisconnectUser", () => {
          this.logout();
        });

      });

      this.connection.start().catch((err) => console.error(err));
    },
    addUserLogged() {
      if (this.userLogged.user.userName != "UserTemp") {
        this.connection
          .invoke("AddUserLogged", this.companyId, this.userId, this.userName, this.codeBrowser);
      }
    },
    logout() {
      this.connection.stop();
      this.addUserLogged({});
      localStorage.setItem("codeBrowser", "");
      window.location.replace("/login");
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "disconnectUsers") {
          this.connection.invoke("DisconnectUser", event.data);
          let self = this;
          setTimeout(function () {
            self.addEvent({ name: "getAllListViewWithDataHandler" });
          }, 200);
        }
      },
      deep: true,
    },
  },
};
</script>
<style scopep>
.full-page {
  height: 100%;
  width: 100%;
  background-color: white;
  left: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
  z-index: 1500;
}

.n-div-center {
  margin: auto;
  width: 80%;
  height: 50%;
  padding: 50px;
}

.n-font-icone {
  font-size: 15px;
}

.icon-siren {
  font-size: 25px;
  color: orange;
}
</style>
