export default class PeriodRent {
    constructor() {
        this.id = "";
        this.deliveryDateHour = "";
        this.startDateHour = "";
        this.endDateHour = "";
        this.devolutionDateHour = "";
        this.description = "";
        this.rentId = "";
    }
    update(data) {
        this.id = data.id;
        this.deliveryDateHour = data.deliveryDateHour;
        this.startDateHour = data.startDateHour;
        this.endDateHour = data.endDateHour;
        this.devolutionDateHour = data.devolutionDateHour;
        this.description = data.description;
        this.rentId = data.rentId;
    }
}