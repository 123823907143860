<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList" :showSearch="false" :showHorizontalFilter="false"
      :propsParam="propsParam">
      <div slot="content-buttons">
        <Button _key="btnSearch" title="Atualizar" classIcon="fa-solid fa-arrows-rotate" size="small" type="info"
          eventName="invoiceCreate" :clicked="clicked" />
      </div>
    </ViewTemplateConfiguration>
  </div>
</template>
  
<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";

import { mapState, mapMutations } from "vuex";

export default {
  name: "Emails",
  components: {
    ViewTemplateConfiguration,
    Button, Confirmation,
    Alert, Modal
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/notification/get-all",
        urlGenerateAllApi: "/api/v1/rental/payment-rent/create-payments-accounts-receivable",
        urlDeleteAllApi: "/api/v1/rental/payment-rent/delete",
        showChecks: false,
        headerTable: [
          {
            field: "registeredDate",
            title: "Envio",
            type: "dateTime",
          },
          {
            field: "tos",
            title: "Para",
            type: "text",
            styleBody: "max-width: 250px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;",
            iconSearch: true,
          },
          {
            field: "description",
            title: "Descrição",
            type: "text",
            styleBody: "max-width: 220px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;",
            iconSearch: true,
          },
          {
            title: "Status",
            field: "statusName",
            type: "html",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "eventDate",
            title: "Último Status",
            type: "dateTime",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
        ],
      },
      propsParam: {
        any: "",
      },
    };
  },
  mounted() {
    this.propsParam.any = this.userLogged.user.id;
  },
  computed: {
    ...mapState("user", ["userLogged"]),
  },
  methods: {
    ...mapMutations("generic", ["clearedSearch"]),
    clicked() {
      this.clearedSearch();
    }
  }

};
</script>
