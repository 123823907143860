import Vue from 'vue'
import App from './App.vue'

import router from './config/router'
import store from './store/store'

import api from './config/axios'

import BootstrapVue from 'bootstrap-vue'
import VueLoading from "vue-loading-template";
import VueTheMask from 'vue-the-mask'
import VueMoment from 'vue-moment'
import Toasted from 'vue-toasted'
import JsonExcel from "vue-json-excel"
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueCurrencyFilter from 'vue-currency-filter'
import * as VueGoogleMaps from 'vue2-google-maps'
import Clipboard from 'v-clipboard'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../node_modules/timeline-vuejs/dist/timeline-vuejs.css'

const moment = require('moment')
require('moment/locale/pt-br')


Vue.use(BootstrapVue)
Vue.use(VueLoading)
Vue.use(VueTheMask)
Vue.use(VueMoment, { moment })
Vue.use(Toasted, {
  position: 'bottom-right',
  duration: 3000,
})
Vue.component("downloadExcel", JsonExcel);
Vue.use(CKEditor);
Vue.use(VueCurrencyFilter,
  {
    symbol: 'R$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
  })
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCAkuPMhL46lgnWNsXhG5-QYPPMiNuevhE',
    libraries: 'places,drawing,visualization',
    region: 'BR',
    language: 'pt-BR',
  },
})
Vue.use(Clipboard)
Vue.prototype.$http = api;
Vue.config.productionTip = false

/*var logger = new Elmahio({
  apiKey: "c8b95988c8ac48dba10f6a20aa6740e2",
  logId: "d1c664fb-19b2-4b17-808e-195ec5d5aef5"
});
Vue.config.errorHandler = function (err, vm, info) {
  logger.error(err.message, err);
};
Vue.config.warnHandler = function (msg, vm, trace) {
  logger.warning(msg);
};*/

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
