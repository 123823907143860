<template>
  <div>
    <slot name="start"></slot>
    <Search />
    <br />
    <div>
      <b-row>
        <b-col sm="12">
          <TableTotalRecords :totalRecords="content.totalRecords" />
        </b-col>
      </b-row>
      <Loading type="line" :center="false" v-show="loading" />
      <ScrollBar id="search-product" :minHeight="400" :maxHeight="400" :endScrollBar="endScrollBar">
        <table class="table table-responsive-xs">
          <thead>
            <tr>
              <th>
                <i class="fas fa-search"></i>
                <span class="title-header">Tipo</span>
              </th>
              <th>
                <i class="fas fa-search"></i>
                <span class="title-header">Nome</span>
              </th>
              <th class="text-center">
                <i class="fas fa-search"></i>
                <span class="title-header">Fab...</span>
              </th>
              <th class="text-center" v-show="showPrice">
                <span class="title-header">Descr...</span>
              </th>
              <th v-if="onlyPatrimony">
                <i class="fas fa-search"></i>
                <span class="title-header">Código/Patrimônio</span>
              </th>
              <th class="text-center" v-if="showQuantity">
                <span class="title-header">Qtd</span>
              </th>
              <th class="text-center">
                <span class="title-header" v-show="showPrice">Valor</span>
                <span class="title-header" v-show="showReplacementValue">Reposição</span>
              </th>
              <th class="text-center">
                <span class="title-header" v-show="showAvailability && !onlyPatrimony">Estoque</span>
              </th>
              <th class="text-center"></th>
            </tr>
          </thead>
          <tbody v-for="product in content.data" :key="product.id">
            <tr>
              <td>
                <div class="div-type-product">
                  <i class="fas fa-box" v-if="product.type == 1"></i>
                  <i class="fas fa-toolbox" v-if="product.type == 2"></i>
                  <i class="fas fa-people-carry" v-if="product.type == 3"></i>
                  <i class="fa-solid fa-boxes-stacked" v-if="product.type == 4"></i>
                  <i class="fa-solid fa-recycle" v-if="product.type == 5"></i>
                  <span class="title-type-product">
                    {{ product.typeProductName }}</span>
                </div>
              </td>
              <td>
                <div class="div-name">
                  <span> {{ product.name }}</span>
                </div>
              </td>
              <td class="text-center">
                <div class="div-manufacturer">
                  <span> {{ product.manufacturerName }}</span>
                </div>
              </td>
              <td v-show="showPrice">
                <div class="editor-html" v-show="idSelected == product.id">
                  <EditorHtml :required="false" :maxLength="2000" v-model="product.description" />
                  <div class="div-icon text-right" @click="selectId('')">
                    <span class="div-icon-close">
                      <i class="fa-sharp fa-solid fa-circle-xmark"></i>
                    </span>
                    <span>
                      <i class="fa-solid fa-circle-check"></i>
                    </span>
                  </div>
                </div>
                <div class="div-description text-center">
                  <div @click="selectId(product.id)">
                    <span v-html="product.description"></span>
                    <i v-if="!product.description" class="icon-edit fa-solid fa-pen-to-square"></i>
                  </div>
                </div>
              </td>
              <td v-if="onlyPatrimony">
                <div class="div-patrimony">
                  <span> {{ product.barCode }}</span>
                </div>
              </td>
              <td class="text-center" v-if="showQuantity">
                <div class="div-quantity">
                  <InputNumber v-if="!onlyPatrimony || product.allowWithoutPatrimony" field="number"
                    _style="text-align:center;font-size:18px;padding-right: 13px;" :required="false" :maxLength="9"
                    type="float" :disabled="!canUpdate" v-model="product.quantity" :params="product" :enter="add" />
                  <span v-else>1</span>
                </div>
              </td>
              <td class="text-center" v-show="showReplacementValue">
                <div class="div-value">
                  <InputDecimal field="number" v-model="product.replacementValue"
                    :disabled="!canUpdate || product.type == 4" :params="product" :enter="add" />
                </div>
              </td>
              <td class="text-center">
                <div class="div-value" v-show="showPrice">
                  <InputDecimal field="number" v-model="product.value" :disabled="!canUpdate || product.type == 4"
                    :params="product" :enter="add" />
                </div>
              </td>
              <td class="text-center">
                <div class="available" v-show="showAvailability && product.type != 3 && !onlyPatrimony">
                  <ItemRentStockAvailability v-if="product.available" :data="product.available" :item="product"
                    :showButtonBack="true" />
                </div>
              </td>
              <td class="text-center" v-show="showAddProduct">
                <div class="div-btn">
                  <Button :_key="product.id" type="primary" classIcon="fa-regular fa-plus-large" size="small"
                    :params="product" :clicked="add" />
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-show="content.data == 0">
            <tr>
              <td colspan="12">
                <span class="title">Nenhum registro encontrado!</span>
              </td>
            </tr>
          </tbody>
        </table>
      </ScrollBar>
    </div>
  </div>
</template>

<script>
import Search from "@nixweb/nixloc-ui/src/component/shared/Search.vue";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";

import ItemRentStockAvailability from "../../rental/items-rent/ItemRentStockAvailability.vue";

import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
  name: "SearchProduct",
  components: {
    Search,
    ScrollBar,
    TableTotalRecords,
    Loading,
    EditorHtml,
    InputText,
    InputNumber,
    InputDecimal,
    Button,
    ItemRentStockAvailability,
  },
  props: {
    types: String,
    status: Number,
    statusPatrimony: Number,
    onlyPatrimony: Boolean,
    periodRent: Object,
    showPrice: Boolean,
    overlay: Boolean,
    showQuantity: {
      type: Boolean,
      default: true
    },
    showReplacementValue: {
      type: Boolean,
      default: false
    },
    canUpdate: {
      type: Boolean,
      default: true
    },
    showAddProduct: {
      type: Boolean,
      default: true
    },
    showAvailability: Boolean,
    addProduct: Function,
  },
  data() {
    return {
      content: {
        data: [],
        totalRecords: 0,
      },
      baseParams: {
        search: undefined,
        filter: "contains",
        currentPage: 1,
        totalPerPage: 30,
      },
      urlGetAllByProduct:
        "/api/v1/stock/search-product/get-all-by-product-or-patrimony",
      loading: false,
      idSelected: ""
    };
  },
  created() {
    this.getAll();
  },
  computed: {
    ...mapState("generic", ["search", "executedSearch", "clearedSearch"]),
    ...mapGetters("user", ["hasPermission"]),
    moduleCurrent() {
      return this.$route.matched[0].props.default.module;
    },
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    ...mapMutations("generic", ["removeLoading"]),
    getAll() {
      this.loading = true;
      let periodRent = this.createPeriodRent(this.periodRent);

      let propsParam = {
        types: this.types,
        status: this.status,
        statusPatrimony: this.statusPatrimony,
        onlyPatrimony: this.onlyPatrimony,
        showPrice: this.showPrice,
        showAvailability: this.showAvailability,
      };

      if (this.baseParams.currentPage == 1) {
        this.content.data = [];
        this.loading = true;
      }

      let obj = { ...this.baseParams, ...propsParam, ...periodRent };
      let params = { url: this.urlGetAllByProduct, obj: obj };

      this.getApi(params).then((response) => {
        let self = this;
        response.content.data.map(function (value, key) {
          self.content.data.push(value);
        });
        this.content.totalRecords = response.content.totalRecords;
        setTimeout(function () {
          self.removeLoading(["btnSearch", "btnClean"]);
          self.loading = false;
        }, 350);
      });
    },
    endScrollBar() {
      if (this.content.data.length != this.content.totalRecords) {
        this.baseParams.currentPage++;
        this.getAll();
      }
    },
    cleanParams() {
      this.baseParams.currentPage = 1;
    },
    add(product) {
      this.addProduct(product);
    },
    createPeriodRent(periodRent) {
      if (!periodRent) return {};
      let obj = {
        deliveryDateHour: this.periodRent.deliveryDateHour,
        startDateHour: this.periodRent.startDateHour,
        endDateHour: this.periodRent.endDateHour,
        devolutionDateHour: this.periodRent.devolutionDateHour,
        minute: this.periodRent.minute,
        hour: this.periodRent.hour,
        day: this.periodRent.day,
        month: this.periodRent.month,
        year: this.periodRent.year,
      };
      return obj;
    },
    selectId(id) {
      this.idSelected = id;
    }
  },
  watch: {
    executedSearch: function () {
      this.baseParams.search = this.search.content;
      if (this.search.filter.id) this.baseParams.filter = this.search.filter.id;
      this.cleanParams();
      this.getAll();
    },
    clearedSearch: function () {
      this.baseParams.search = "";
      this.baseParams.filter = "";
      this.cleanParams();
      this.getAll();
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 5px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: 3px;
}

.title-type-product {
  margin-left: 6px;
}

.div-type-product {
  width: 200px;
  margin-top: 16px;
}

.div-patrimony {
  width: 250px;
  word-wrap: break-word;
  margin-top: 14px;
}

.div-name {
  max-width: 300px;
  margin-top: 15px;
}

.div-manufacturer {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  margin-top: 15px;
}

.div-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  margin-top: 15px;
  color: #3f529b;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.div-description.hover {
  text-decoration: underline;
}


.div-name-margin {
  margin-top: 15px;
}

.div-quantity {
  width: 90px;
  margin: auto;
  padding-top: 10px;
}

.available {
  margin-top: 15px;
}

.div-value {
  width: 150px;
  margin: auto;
  padding-top: 10px;
}

.div-btn {
  padding-top: 12px;
}

.description {
  color: rgb(116, 113, 113);
  font-style: italic;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.editor-html {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: white;
  border: 1px solid #E8EAED !important;
  border-radius: 10px;
  padding: 10px 15px 0px 15px;
  z-index: 9999;
}

.icon-edit {
  color: #979DA8 !important;
}

.div-icon {
  font-size: 22px;
  cursor: pointer;
}

.fa-circle-check {
  color: green;
}

.fa-circle-xmark {
  color: red;
}

.div-icon-close {
  margin-right: 5px;
}
</style>
