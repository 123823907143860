<template>
  <div>
    <b-row>
      <b-col sm="12" v-if="showGenerate">
        <div class="text-center">
          <RadioGroup :title="msg" field="CalculateByFullPeriod" :formName="formName" :options="[
        { text: 'Gerador', value: true },
        { text: 'Manual', value: false },
      ]" v-model="modeGenerate" />
        </div>
      </b-col>
    </b-row>
    <div v-show="modeGenerate">
      <GeneratePeriodRent :isShowStartDateHour="true" v-model="periodRent" />
    </div>
    <div v-show="!modeGenerate">
      <b-row>
        <b-col sm="3">
          <DateTime title="Saída" field="deliveryDateHour" format="DD/MM/YYYY HH:mm" type="dateTime" placeholder
            :formName="formName" :markFormDirty="markFormDirty" :required="true"
            v-model="periodRent.deliveryDateHour" />
        </b-col>
        <b-col sm="3">
          <DateTime title="Inicio" field="startDateHour" format="DD/MM/YYYY HH:mm" type="dateTime" placeholder
            :formName="formName" :markFormDirty="markFormDirty" :required="true" v-model="periodRent.startDateHour" />
        </b-col>
        <b-col sm="3">
          <DateTime title="Fim" field="endDateHour" format="DD/MM/YYYY HH:mm" type="dateTime" placeholder
            :formName="formName" :markFormDirty="markFormDirty" :required="true" v-model="periodRent.endDateHour" />
        </b-col>
        <b-col sm="3">
          <DateTime title="Retorno" field="devolutionDateHour" format="DD/MM/YYYY HH:mm" type="dateTime" placeholder
            :formName="formName" :markFormDirty="markFormDirty" :required="true"
            v-model="periodRent.devolutionDateHour" />
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <InputText title="Descrição" field="descricao" :formName="formName" :required="false" :maxLength="100"
            v-model="periodRent.description" :markFormDirty="markFormDirty" />
        </b-col>
      </b-row>
    </div>
    <b-row v-if="showBtnSave">
      <b-col sm="12">
        <div class="text-right">
          <Button _key="savePeriodRent" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="savePeriodRent" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";

import GeneratePeriodRent from "@/components/modules/rental/period-rental/GeneratePeriodRent.vue";
import PeriodRent from "@/components/modules/rental/period-rental/PeriodRent.js";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "PeriodRentCreateUpdate",
  props: {
    rentId: String,
    msg: String,
    showBtnSave: {
      type: Boolean,
      default: true,
    },
    showModeGenerate: {
      type: Boolean,
      default: true,
    },
    markFormDirty: {
      type: Boolean,
      default: false,
    },
    value: Object,
  },
  components: {
    DateTime,
    InputText,
    Button,
    RadioGroup,
    GeneratePeriodRent,
  },
  data() {
    return {
      periodRent: new PeriodRent(),
      formName: "periodRentCreateUpdate",
      urlCreate: "/api/v1/rental/period-rent/create",
      urlUpdate: "/api/v1/rental/period-rent/update",
      urlGenerate: "/api/v1/rental/period-rent/generate-manual",
      readOnly: false,
      showGenerate: true,
      modeGenerate: true,
    };
  },
  mounted() {
    this.periodRent = this.value;
    if (this.rentId) this.periodRent.rentId = this.rentId;
    this.modeGenerate = this.showModeGenerate;
    this.showGenerate = this.showModeGenerate;
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading", "hideModal"]),
    savePeriodRent() {
      if (this.periodRent.id && !this.readOnly) {
        let params = { url: this.urlUpdate, obj: this.periodRent };
        this.putApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.removeLoading(["savePeriodRent"]);
        });
      } else if (!this.readOnly) {
        this.periodRent.rentId = this.rentId;
        let params = { url: this.urlCreate, obj: this.periodRent };
        this.postApi(params).then((response) => {
          if (response.success) this.hideModal();
          this.removeLoading(["savePeriodRent"]);
        });
      } else if (this.readOnly) {
        let params = {
          url: this.urlGenerate,
          obj: this.periodRent,
          notNotifyToast: true,
        };
        this.postApi(params).then((response) => {
          if (response.success) {
            this.addEvent({
              name: "generatePeriodRent",
              data: response.content,
            });
            this.hideModal();
          }
          this.removeLoading(["savePeriodRent"]);
        });
      }
    },
    update(data) {
      this.periodRent = new PeriodRent();
      this.periodRent.update(data);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "periodRentCreateUpdate") {
          this.showGenerate = true;
          this.modeGenerate = true;
          this.readOnly = false;
          this.periodRent = new PeriodRent();
        }
        if (event.name == "periodRentUpdate") {
          this.showGenerate = false;
          this.modeGenerate = false;
          this.readOnly = false;
          this.update(event.data.fullPeriod);
        }
        if (event.name == "clickedPeriodRentItem") {
          this.modeGenerate = false;
          this.readOnly = true;
          this.update(event.data);
        }
      },
      deep: true,
    },
    periodRent: {
      handler(periodRent) {
        this.$emit("input", periodRent);
      },
      deep: true,
    },
  },
};
</script>
