<template>
  <div>
    <Loading type="line" :center="false" v-show="loading" />
    <b-row>
      <b-col sm="10">
        <div class="title text-left" v-if="!loading">
          {{ data.productName }} - Estoque {{ data.totalStock }}
        </div>
      </b-col>
      <b-col sm="2">
        <div class="text-right" v-show="!loading && showButtonBack">
          <Button _key="btnBackTimeLineAvailability" type="info" classIcon="fa-solid fa-arrow-left" size="small"
            eventName="btnBackTimeLineAvailability" />
        </div>
      </b-col>
    </b-row>
    <div v-if="!loading">
      <div class="div-legend text-center">
        <div class="side-by-side box-time dot-maintenance"></div>
        <div class="side-by-side title magin">Manutenção</div>
        <div class="side-by-side box-time dot-rented"></div>
        <div class="side-by-side title magin">Locado</div>
        <div class="side-by-side box-time dot-reserved"></div>
        <div class="side-by-side title magin">Reservado</div>

      </div>
    </div>
    <Gantt v-if="!loading" initialOption="Dia" :options="options" :tasks="data.tasks">
    </Gantt>
  </div>
</template>
<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Gantt from "@nixweb/nixloc-ui/src/component/layout/Gantt";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapMutations, mapState, mapActions } from "vuex";

export default {
  name: "TimeLineAvailability",
  props: {
    item: Object,
  },
  components: {
    Button,
    Gantt,
    Loading,
  },
  data() {
    return {
      urlGet: "/api/v1/stock/search-product/list-items-timeline",
      options: [
        { title: "Dia", eventName: "demoViewMode" },
        { title: "Semana", eventName: "demoViewMode" },
        { title: "Mês", eventName: "demoViewMode" },
      ],
      data: {},
      loading: true,
      showDisplay: true,
      showButtonBack: false,
    };
  },
  mounted() {
    this.get();
  },
  computed: {
    ...mapState("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    ...mapMutations("generic", ["removeLoading"]),
    get(item) {
      let params = {
        url: this.urlGet,
        obj: {
          productId: item.productId,
          productFullName: item.productFullName,
          totalStock: item.totalStock,
        },
      };
      this.getApi(params).then((response) => {
        this.data = response.content;
        let self = this;
        setTimeout(function () {
          self.loading = false;
        }, 500);
        this.removeLoading(["btnPanelReserved"]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "timeLineAvailability") {
          this.loading = true;
          this.showButtonBack = event.data.showButtonBack;
          this.get(event.data.item);
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.title {
  margin-bottom: 15px;
  font-size: 15px;
}

.magin {
  margin-right: 15px;
}

.box-time {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin-right: 3px;
}

.dot-maintenance {
  background-color: #FFC106;
}

.dot-reserved {
  background-color: #018B8B;
}

.dot-rented {
  background-color: #00058B;
}
</style>