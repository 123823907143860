<template>
    <div>
        <div v-html="comunication.htmlContent"></div>
        <br>
        <CheckboxSimple title="Não mostrar novamente" :markFormDirty="false" v-model="dontShowAgain" />
    </div>
</template>
<script>

import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple.vue";

import { mapState, mapActions, mapMutations } from "vuex";

export default {
    name: "Comunication",
    components: {
        CheckboxSimple
    },
    props: {
        comunication: Object,
    },
    data() {
        return {
            urlUpdate: "/api/v1/adm/user/update-comunication",
            dontShowAgain: false
        }
    },
    computed: { ...mapState("user", ["userLogged"]), },
    methods: {
        ...mapActions("generic", ["putApi"]),
        ...mapMutations("user", ["updateComunication"]),
        ...mapMutations("generic", ["hideModal"]),
        update() {
            let params = { url: this.urlUpdate, obj: { any: this.userLogged.user.id },  notNotifyToast: true };
            this.putApi(params).then(() => {
                this.updateComunication();
                this.hideModal();
            });
        }
    },
    watch: {
        dontShowAgain: {
            handler() {
                this.update();
            },
            deep: true,
        },
    },
}

</script>