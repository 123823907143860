<template>
    <div>
        <b-tabs v-model="tabIndex">
            <b-tab title="Período">
                <PeriodRentCreateUpdate msg="Como deseja Gerar o Período de Consulta?" :showBtnSave="false"
                    v-model="periodRent" />
            </b-tab>
            <b-tab title="Produto">
                <div v-if="isValid">
                    <div class="text-center div-molded">
                        <DisplayPeriodRent :showDeliveryDevolution="true" :periodRent="periodRent" />
                    </div>
                    <SearchProduct v-if="tabIndex == 1" :types="'1,2,3,4,5'" :status="1" :showPrice="hasRule('Rental')"
                        :showAddProduct="false" :showQuantity="false" :canUpdate="false" :showAvailability="true"
                        :periodRent="periodRent">
                    </SearchProduct>
                </div>
                <Alert v-else type="warning">
                    <span> É necessário informar o período de locação! </span>
                </Alert>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>

import PeriodRentCreateUpdate from '../../rental/period-rental/PeriodRentCreateUpdate.vue'
import SearchProduct from '../search-product/SearchProduct.vue'
import DisplayPeriodRent from "@nixweb/nixloc-ui/src/component/rental/DisplayPeriodRent";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import { mapGetters } from "vuex";

export default {
    name: "AvailabilityProduct",
    components: { PeriodRentCreateUpdate, SearchProduct, Alert, DisplayPeriodRent },
    data() {
        return {
            tabIndex: 0,
            periodRent:
            {
                deliveryDateHour: "",
                startDateHour: "",
                endDateHour: "",
                devolutionDateHour: "",
                description: null,
                minute: 0,
                hour: 0,
                day: 1,
                month: 0,
                year: 0,
                totalDays: 1
            },
        }
    },
    computed: {
        ...mapGetters("user", ["hasRule"]),
        isValid() {
            if (this.periodRent.deliveryDateHour != ""
                && this.periodRent.startDateHour != ""
                && this.periodRent.endDateHour != ""
                && this.periodRent.devolutionDateHour != "") return true;

            return false;
        }
    }
}

</script>

<style scoped>
.div-molded {
    border: 1px solid #e5e4e8;
    width: 750px;
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 7px;
    font-size: 14px;
    font-weight: normal;
    margin: auto;
}
</style>