export default {
    namespaced: true,
    state: {
        type: "",
        periodRent: {},
        productRent: [],
        productRentPatrimony: [],
        productSelected: [],
        filterStorage: { showAll: false, selectedPeriod: [], period: "today" },
    },
    getters: {
        productSelectedByPeriod: (state) => {
            var filter = state.productSelected.filter(x => x.periodRentId == state.periodRent.id
                && x.type == state.type);
            return filter;
        },
        verifyIfExistSelected: (state) => (item) => {
            var isExist = state.productSelected.find(obj => {
                return obj.barCode === item.barCode && obj.productId == item.productId
                    && obj.periodRentId == state.periodRent.id
                    && obj.type == state.type
            })
            if (isExist != undefined) return true;
            return false;
        },
        verifyIfExistRented: (state) => (item) => {
            var isExist = state.productRent.find(obj => {
                return obj.productId == item.productId
            })
            if (isExist != undefined) return true;
            return false;
        },
        verifyIfExistPatrimony: (state) => (item) => {
            var isExist = state.productRentPatrimony.find(obj => {
                return obj.barCode === item.barCode && obj.productId == item.productId
            })
            if (isExist != undefined) return true;
            return false;
        },
        quantityMax: (state) => (item) => {
            var filter = state.productRent.filter(x => x.productId == item.productId);
            return filter.length > 0 ? filter[0].quantity : 0;
        },
        totalSelectedByProductId: (state) => (productId) => {
            var filter = state.productSelected.filter(x => x.productId == productId
                && x.periodRentId == state.periodRent.id
                && x.type == state.type);
            var quantity = [];
            filter.forEach(item => {
                quantity.push(item.quantity);
            });

            const sum = quantity.reduce((a, b) => a + b, 0);

            return sum;
        }
    },
    mutations: {
        addType: (state, type) => {
            state.type = type;
        },
        addPeriodRent: (state, obj) => {
            state.periodRent = obj;
        },
        addProductRent: (state, list) => {
            state.productRent = list;
            state.productRentPatrimony = [];
            state.productRent.forEach(product => {
                if (product.patrimony) {
                    product.patrimony.forEach(patrimony => {
                        let obj = {
                            productId: product.productId,
                            barCode: patrimony.barCode,
                        }
                        state.productRentPatrimony.push(obj);
                    });
                }
            });
        },
        cleanProductRent: (state) => {
            state.productRent = [];
        },
        addProductSelected: (state, obj) => {
            obj.periodRentId = state.periodRent.id;
            state.productSelected.push(obj);
        },
        removeProductSelected: (state, obj) => {
            let filter = state.productSelected.filter(x => x.id != obj.id);
            state.productSelected = filter;
        },
        cleanProductSelected: (state) => {
            state.productSelected = [];
        },
        addFilterStorage: (state, obj) => {
            state.filterStorage = obj;
        },
    },
}