<template>
    <div>
        <ViewTemplateConfiguration :templateList="templateList" :showSearch="false" :showHorizontalFilter="false">
            <div slot="content-buttons">
                <Button _key="btnSearch" title="Atualizar" classIcon="fa-solid fa-arrows-rotate" size="small" type="info"
                    :clicked="clicked" />
            </div>
        </ViewTemplateConfiguration>
    </div>
</template>
    
<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapMutations, mapGetters } from "vuex";

export default {
    name: "SignerListForMessaging",
    components: {
        ViewTemplateConfiguration,
        Button
    },
    data() {
        return {
            templateList: {
                urlGetApi: "/api/v1/signer/get-all-for-messaging",
                showChecks: false,
                headerTable: [
                    {
                        field: "registeredDate",
                        title: "Envio",
                        type: "dateTime",
                    },
                    {
                        field: "name",
                        title: "Para",
                        type: "text",
                        styleBody: "max-width: 200px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;",
                    },
                    {
                        field: "email",
                        title: "",
                        type: "text",
                        styleBody: "max-width: 220px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;",
                    },
                    {
                        field: "signUrl",
                        title: "Link",
                        type: "text",
                        styleBody: "max-width: 550px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;",
                    },
                    {
                        type: "button",
                        typeButton: "default",
                        classCssTitle: "text-center",
                        classCssBody: "text-center",
                        button: {
                            classIcon: "fa-regular fa-copy",
                            type: "default",
                            size: "small",
                            eventName: "copyLinkSignature",
                        },
                    },
                    {
                        field: "statusName",
                        title: "Status",
                        type: "class",
                        fieldComparison: "statusName",
                        classCssTitle: "text-center",
                        classCssBody: [
                            {
                                classCss: "text-center badge badge-warning",
                                fieldComparison: "Pendente",
                            },
                            {
                                classCss: "text-center badge badge-success",
                                fieldComparison: "Assinado",
                            },
                            {
                                classCss: "text-center badge badge-info",
                                fieldComparison: "Aberto",
                            },
                            {
                                classCss: "text-center badge badge-danger",
                                fieldComparison: "Recusado",
                            },
                        ],
                    },
                ],
            },
        };
    },
    computed: {
        ...mapGetters("generic", ["event"]),
    },
    methods: {
        ...mapMutations("generic", ["clearedSearch"]),
        clicked() {
            this.clearedSearch();
        }
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "copyLinkSignature") {
                    navigator.clipboard.writeText(event.data.signUrl);
                    this.$toasted.show("Link copiado...", { type: "info" });
                }
            },
            deep: true,
        },
    }
};
</script>
  